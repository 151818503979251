import React, { useEffect, useState } from "react";
import { Form as BSForm } from "react-bootstrap";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";

import SportSingleSelect from "./form/sport-single-select";
import { CaseStudyFilterValues } from "../types/case-study-filter-values.interface";
import PrioritisationSelect from "./form/prioritisation-select";
import { Sports } from "../types/form/sports.enum";
import { Prioritisation } from "../types/form/prioritisation.enum";
import TimePeriodSelect from "./time-period-select";

interface Props {
  onReset: (event: React.MouseEvent<HTMLElement>) => void;
  onSubmit: (value: CaseStudyFilterValues) => void;
}

const CaseStudyFilters = ({ onReset, onSubmit }: Props) => {
  const intl = useIntl();
  const initialValues: CaseStudyFilterValues = {
    minDuration: 0,
    maxDuration: 52,
    timePeriod: "All",
    sport: "All",
    prioritisation: "All",
  };

  const [minDuration, setMinDuration] = useState(initialValues.minDuration);
  const [maxDuration, setMaxDuration] = useState(initialValues.maxDuration);
  const [timePeriod, setTimePeriod] = useState(initialValues.timePeriod);
  const [sport, setSport] = useState<Sports | string | undefined>(
    initialValues.sport
  );
  const [prioritisation, setPrioritisation] = useState<
    Prioritisation | string | undefined
  >(initialValues.prioritisation);

  useEffect(() => {
    onSubmit({
      minDuration: minDuration,
      maxDuration: maxDuration,
      timePeriod: timePeriod,
      sport: sport,
      prioritisation: prioritisation,
    });
  }, [minDuration, maxDuration, timePeriod, sport, prioritisation]);

  const handleReset = () => {
    setMinDuration(initialValues.minDuration);
    setMaxDuration(initialValues.maxDuration);
    setTimePeriod(initialValues.timePeriod);
    setPrioritisation(initialValues.prioritisation);
    setSport(initialValues.sport);
  };

  return (
    <form>
      <div className="row">
        <div className="col-6">
          <PrioritisationSelect
            name="project"
            label={intl.formatMessage({
              id: "prioritisation",
              defaultMessage: "Prioritisation",
            })}
            value={prioritisation}
            onChange={(e) => setPrioritisation(e.target.value)}
            onBlur={(e) => setPrioritisation(e.target.value)}
            selectAllEnabled={true}
          ></PrioritisationSelect>
        </div>
        <div className="col-6">
          <BSForm.Group className="mb-3">
            <BSForm.Label>
              {" "}
              <FormattedMessage id="sport" defaultMessage="Sport" />
            </BSForm.Label>

            <SportSingleSelect
              name="sport"
              selectAllEnabled={true}
              onChange={(e) => setSport(e.target.value)}
              value={sport}
            ></SportSingleSelect>
          </BSForm.Group>
        </div>
        <div className="col-6">
          <BSForm.Group className="mb-3">
            <BSForm.Label>
              {" "}
              <FormattedMessage id="date_range" defaultMessage="Date Range" />
            </BSForm.Label>
            <TimePeriodSelect
              name="timePeriod"
              selectAllEnabled={true}
              onChange={(e) => setTimePeriod(e.target.value)}
              value={timePeriod}
            ></TimePeriodSelect>
            <BSForm.Text>
              {" "}
              <FormattedMessage
                id="date_range_help"
                defaultMessage="Applies to end date of case study"
              />
            </BSForm.Text>
          </BSForm.Group>
        </div>
        <div className="col-6">
          <BSForm.Label>
            <FormattedMessage
              id="duration"
              defaultMessage="Duration in Weeks"
            />
          </BSForm.Label>

          <BSForm.Group className="mb-3 d-flex align-items-baseline">
            <div>
              <BSForm.Label className="visually-hidden" htmlFor="durationMin">
                <FormattedMessage
                  id="minimum_duration_in_weeks"
                  defaultMessage="Minimum duration in weeks"
                />
              </BSForm.Label>
              <BSForm.Control
                type="number"
                id="durationMin"
                name="minimumDuration"
                min="0"
                max=""
                value={minDuration}
                onChange={(e) => setMinDuration(e.target.value)}
              />
              <BSForm.Text>
                <FormattedMessage
                  id="minimum_duration"
                  defaultMessage="Minimum"
                />
              </BSForm.Text>
            </div>
            <div className="pb-2 px-1">–</div>
            <div>
              <BSForm.Label className="visually-hidden" htmlFor="durationMax">
                <FormattedMessage
                  id="maximum_duration_in_weeks"
                  defaultMessage="Maximum duration in weeks"
                />
              </BSForm.Label>
              <BSForm.Control
                type="number"
                id="durationMax"
                name="maximumDuration"
                min="0"
                max=""
                value={maxDuration}
                onChange={(e) => setMaxDuration(e.target.value)}
              />
              <BSForm.Text className="form-text">
                {" "}
                <FormattedMessage
                  id="maximum_duration"
                  defaultMessage="Maximum "
                />
              </BSForm.Text>
            </div>
          </BSForm.Group>
        </div>
        <div className="col-12 mt-3 d-flex">
          <button
            type="button"
            className="btn btn-outline-secondary ms-auto"
            onClick={handleReset}
          >
            <FormattedMessage
              id="reset_filters"
              defaultMessage="Reset Filters"
            />
          </button>
        </div>
      </div>
    </form>
  );
};
export default CaseStudyFilters;
