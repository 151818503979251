import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import * as JsSearch from "js-search";

import Layout from "../components/layout";
import Breadcrumbs from "../components/breadcrumbs";
import CaseStudyOverviewTable from "../components/case-study-overview-table";
import SearchBar from "../components/search-bar";
import CaseStudyFilters from "../components/case-study-filters";
import { CaseStudyFilterValues } from "../types/case-study-filter-values.interface";
import { Sports } from "../types/form/sports.enum";
import dayjs from "dayjs";
import { TimePeriod } from "../types/time-period.enum";

interface CaseStudy {
  id: React.Key | null | undefined;
  thumbnail: any;
  title:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  prioritisation: string;
  sport: string;
  startDate: Date;
  endDate: Date;
  parent: { name: string };
}

const CaseStudies = (props: {
  data: { allMarkdownRemark: { nodes: any[] } };
  pageContext: { language: any };
}) => {
  const intl = useIntl();

  const [caseStudies, setCaseStudies] = useState<CaseStudy[]>(
    props.data.allMarkdownRemark.nodes
      .filter((node) => {
        let str = node.parent.relativeDirectory;
        let n = str.lastIndexOf("/");
        let lang = str.substring(n + 1);
        return lang === props.pageContext.language;
      })
      .map((caseStudy) => {
        return {
          title: caseStudy.frontmatter.title,
          sport: caseStudy.frontmatter.sport,
          prioritisation: caseStudy.frontmatter.prioritisation,
          thumbnail: caseStudy.frontmatter.thumbnail,
          startDate: caseStudy.frontmatter.startDate,
          endDate: caseStudy.frontmatter.endDate,
          parent: caseStudy.parent,
          id: caseStudy.id,
        };
      })
  );
  const [search, setSearch] = useState<JsSearch.Search>();
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [queryResults, setQueryResults] = useState(caseStudies);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState<CaseStudyFilterValues>();

  useEffect(() => {
    rebuildIndex();
    setQueryResults(searchQuery === "" ? caseStudies : searchResults);
  }, [searchResults]);
  const rebuildIndex = () => {
    const dataToSearch = new JsSearch.Search("title");

    /**
     * defines an indexing strategy for the data
     * more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("title");
    dataToSearch.addIndex("title"); // sets the index attribute for the data
    dataToSearch.addIndex("sport"); // sets the index attribute for the data
    dataToSearch.addIndex("prioritisation"); // sets the index attribute for the data
    dataToSearch.addDocuments(caseStudies); // adds the data to be searched
    setSearch(dataToSearch);
    setIsLoading(false);
  };

  /**
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  const searchData = (e: { target: any }) => {
    // const { search } = this.state
    if (search?.search) {
      const queryResult = search.search(e.target.value);
      setSearchQuery(e.target.value);
      setSearchResults(queryResult);
    }
  };
  const resetForm = () => {
    setSearchQuery("");
    setSearchResults([]);
  };

  const handleSearchReset = () => {
    resetForm();
  };

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLElement>) => {
    searchData(event);
  };

  const filterCaseStudies = (filterValues: CaseStudyFilterValues) => {
    const { sport, prioritisation, minDuration, maxDuration, timePeriod } =
      filterValues;

    setFilters({
      sport: sport,
      prioritisation: prioritisation,
      minDuration: minDuration,
      maxDuration: maxDuration,
      timePeriod: timePeriod,
    });

    setQueryResults(searchQuery === "" ? caseStudies : searchResults);

    const results = searchQuery === "" ? caseStudies : searchResults;

    const filteredResults = results.filter((caseStudy: CaseStudy) => {
      const hasAllSports = sport === "All";
      const hasSport = !!sport && caseStudy.sport === sport;

      const hasAllPrioritisations = prioritisation === "All";
      const hasPrioritisation =
        !!prioritisation && caseStudy.prioritisation === prioritisation;

      const hasAllDates = timePeriod === "All";
      const withinDateRange = isWithinDateRange(caseStudy, timePeriod);

      const noCaseStudyDuration = !caseStudy.startDate || !caseStudy.endDate;

      let duration = 0;

      if (!noCaseStudyDuration) {
        let { startDate, endDate } = caseStudy;

        if (!!startDate && !!endDate) {
          const startDateDayJS = dayjs(startDate);
          const endDateDayJs = dayjs(endDate);
          duration = endDateDayJs.diff(startDateDayJS, "week");
        }
      }

      const withinDurationRange =
        duration >= minDuration && duration <= maxDuration;

      return (
        (hasAllSports || hasSport) &&
        (hasAllPrioritisations || hasPrioritisation) &&
        (hasAllDates || withinDateRange) &&
        (noCaseStudyDuration || withinDurationRange)
      );
    });
    setQueryResults(filteredResults);
  };

  /**
   * Checks if end date is wihtin the date range specified
   * @param {CaseStudy} - the case study to check
   * @param {TimePeriod | string | undefined} - time periods defined by TimePeriod enum
   * @return {boolean}
   */
  const isWithinDateRange = (
    caseStudy: CaseStudy,
    timePeriod: TimePeriod | string | undefined
  ) => {
    if (!timePeriod) {
      return false;
    } else {
      let timePeriodStartDate;

      switch (TimePeriod[timePeriod]) {
        case TimePeriod.MONTH.toString():
          timePeriodStartDate = dayjs().subtract(1, "month");
          break;
        case TimePeriod.QUARTER.toString():
          timePeriodStartDate = dayjs().subtract(3, "month");
          break;
        case TimePeriod.HALF_YEAR.toString():
          timePeriodStartDate = dayjs().subtract(6, "month");
          break;
        case TimePeriod.YEAR.toString():
          timePeriodStartDate = dayjs().subtract(1, "year");
          break;
        default:
          timePeriodStartDate = dayjs().subtract(5, "year");
          break;
      }

      const endDateDayJS = dayjs(caseStudy.endDate);
      return endDateDayJS.isAfter(timePeriodStartDate);
    }
  };

  return (
    <Layout>
      <Breadcrumbs
        parentPageUrl="/resources"
        parentPageTitle={
          <FormattedMessage
            id="resources.back_to_resources"
            defaultMessage="Resources"
          />
        }
        currentPageTitle={
          <FormattedMessage id="case_studies" defaultMessage="Case Studies" />
        }
      ></Breadcrumbs>
      <h1 className="block-title">
        <FormattedMessage id="case_studies" defaultMessage="Case Studies" />
      </h1>
      <div className="card mb-3">
        <div className="card-header bg-secondary">
          <h2 className="text-white h3 mb-1">
            <FormattedMessage
              id="search_and_filter"
              defaultMessage="Search and Filter"
            />
          </h2>
        </div>
        <div className="card-body">
          <div className="row">
            <SearchBar
              onReset={handleSearchReset}
              onChange={(e) => handleOnSearchChange(e)}
              searchQuery={searchQuery}
            ></SearchBar>
          </div>
          <CaseStudyFilters
            onReset={resetForm}
            onSubmit={(values) => filterCaseStudies(values)}
          ></CaseStudyFilters>
        </div>
      </div>
      {!!searchQuery.length && (
        <h3 className="search-results-title">
          <FormattedMessage
            id="resources.results_title"
            defaultMessage=" Showing {itemCount ,plural,
                =0 {no results}
                one {1 result}
                other {{itemCount} results}} for {query}"
            values={{
              itemCount: queryResults.length,
              query: searchQuery,
            }}
          />
        </h3>
      )}
      {
        <div className="mb-3">
          <h3 className="search-results-title">
            <FormattedMessage
              id="resources.results_filters_title"
              defaultMessage=" Filters Applied:"
            />
          </h3>
          <span className="badge bg-primary">
            {!!filters?.prioritisation && (
              <FormattedMessage
                id={`prioritisation.${filters?.prioritisation?.toLowerCase()}`}
                defaultMessage={filters?.prioritisation}
              />
            )}
          </span>{" "}
          <span className="badge bg-secondary">
            {!!filters?.sport && (
              <FormattedMessage
                id={`sport.${filters?.sport?.toLowerCase()}`}
                defaultMessage={filters?.sport}
              />
            )}
          </span>{" "}
          <span className="badge bg-black">
            {(filters?.timePeriod === "All" || !filters?.timePeriod) && (
              <FormattedMessage
                id="case_studies.all_dates"
                defaultMessage="All Dates"
              />
            )}
            {!!filters?.timePeriod && TimePeriod[filters?.timePeriod]}
          </span>{" "}
          <span className="badge bg-black">
            <FormattedMessage
              id="case_studies.duration_filter"
              defaultMessage="Duration {min}–{max} weeks"
              values={{
                min: filters?.minDuration,
                max: filters?.maxDuration,
              }}
            />
          </span>
        </div>
      }
      <ul className="case-studies-list">
        {!isLoading &&
          queryResults.map(
            (
              node: {
                id: React.Key | null | undefined;
                thumbnail: any;
                title:
                  | boolean
                  | React.ReactChild
                  | React.ReactFragment
                  | React.ReactPortal
                  | null
                  | undefined;
                prioritisation: string;
                sport: string;
                startDate: Date;
                endDate: Date;
                parent: { name: string };
              },
              i: any
            ) => (
              <li key={node.id} className="case-study-item">
                <div className="card h-100">
                  <div className="card-img-top-container">
                    <img
                      className="card-img-top"
                      src={`${
                        node.thumbnail ? node.thumbnail : "/img/logo.png"
                      }`}
                      alt="Sport Wales Logo"
                    />
                  </div>

                  <div className="card-header">
                    {" "}
                    <h3 className="case-study-item__header">{node.title}</h3>
                  </div>
                  <div className="card-body">
                    <CaseStudyOverviewTable
                      prioritisation={node.prioritisation}
                      sport={node.sport}
                      startDate={node.startDate}
                      endDate={node.endDate}
                    ></CaseStudyOverviewTable>
                  </div>
                  <div className="card-footer bg-primary text-white rounded-0 p-0">
                    <Link
                      className="case-study-item__link d-flex align-items-center text-white p-2 card-footer-link"
                      to={node.parent.name}
                    >
                      <FormattedMessage id="details" defaultMessage="Details" />
                      <span className="visually-hidden">{node.title}</span>
                      <img
                        className="case-study-item__link-icon ms-1"
                        src="/img/chevron-right-white.svg"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </li>
            )
          )}
      </ul>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(case-studies)/" } }
    ) {
      nodes {
        frontmatter {
          title
          sport
          prioritisation
          startDate
          endDate
          thumbnail
        }
        fields {
          slug
        }
        excerpt
        parent {
          ... on File {
            name
            relativeDirectory
          }
        }
        id
      }
    }
  }
`;
export default CaseStudies;
