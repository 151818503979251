import React from "react";
import { Form } from "react-bootstrap";
import { useIntl } from "gatsby-plugin-intl";
import { TimePeriod } from "../types/time-period.enum";

interface Props {
  name: string;
  value: TimePeriod | string | undefined;
  selectAllEnabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const TimePeriodSelect = ({ onChange, value, selectAllEnabled }: Props) => {
  const intl = useIntl();

  return (
    <Form.Select
      aria-label={intl.formatMessage({
        id: "select_a_time_period",
        defaultMessage: "Select a time period",
      })}
      onChange={(e) => onChange(e)}
      value={value}
      required
    >
      {selectAllEnabled && (
        <option value="All">
          {intl.formatMessage({
            id: "all_dates",
            defaultMessage: "All Dates",
          })}
        </option>
      )}

      {(Object.keys(TimePeriod) as Array<keyof typeof TimePeriod>).map(
        (val) => {
          return (
            <option key={val} value={val}>
              {TimePeriod[val]}
            </option>
          );
        }
      )}
    </Form.Select>
  );
};

export default TimePeriodSelect;
